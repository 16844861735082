<template>
  <div>
    <el-row>
      <pre class="description">{{ question.paperQuestion.description }}</pre>
    </el-row>
    <el-row>
      <div v-for="(b) in question.paperQuestion.paperBlanks" :key="b.id">
        <div style="margin: 8px">第{{ b.orderNumber }}空({{ b.score }}分):
          <el-input v-model="record[b.id]" @change="storeRecord"></el-input>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Completion',
  data () {
    return {
      record:
        {}
    }
  },
  props: {
    question: Object
  },
  created () {
    this.createRecord()
  },
  methods: {
    createRecord () {
      this.question.paperQuestion.paperBlanks.forEach(function (item) {
        this.record.put(item.id, '')
      })
    },
    storeRecord () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/paperQuestionRecord/storeRecord',
        data: {
          examId: this.question.examId,
          paperQuestionId: this.question.paperQuestionId,
          record: JSON.stringify(this.record)
        },
        transformRequest: [
          function (data) {
            let ret = ''
            for (const it in data) {
              ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            ret = ret.substring(0, ret.lastIndexOf('&'))
            return ret
          }
        ],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        if (res.status === 200) {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.description {
  margin: 0;
  font-size: 20px;
}
</style>
