<template>
  <div class="bg-light" style="padding: 100px 50px">
    <div class="container-fluid">
      <el-row>
        <el-col :md="18">
          <el-card>
            <el-row>
              <h2>{{ paper.exam.name }}</h2>
              <span>开始时间:<i class="el-icon-time" style="margin: 0 3px"></i>{{ paper.score.startTime }}</span>
              <span style="margin-left: 50px">考试时间:<i class="el-icon-time" style="margin: 0 3px"></i>{{
                  paper.exam.duration
                }} min</span>
            </el-row>
            <el-divider></el-divider>
            <div class="questionSection" v-for="(qs,key,i) in paper.questions" :key="i">
              <h3>{{ order[i] }}、{{ getType(key) }}</h3>
              <el-row :gutter="10" v-for="(q,i) in qs" :key="q.id">
                <el-col :span="1" style="text-align: center;vertical-align: middle"><p style="margin-top: 7px">{{
                    i + 1
                  }}、</p>
                </el-col>
                <el-col :span="20">
                  <div class="answer">
                    <SingleQuestion v-if="key==='single'" :question="q" :disabled="true"></SingleQuestion>
                    <MutualQuestion :question="q" v-else-if="key==='multiple'" :disabled="true"></MutualQuestion>
                    <Completion :question="q" v-else-if="key==='completion'"></Completion>
                  </div>
                </el-col>
                <el-col :span="3">
                  <el-tag effect="plain" type="info">{{ q.paperQuestion.score }}分</el-tag>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :md="6" style="padding: 0 10px;">
          <el-card style="position:sticky">
            <h3>试题总览</h3>
            <el-divider></el-divider>
            <div class="questionSection" v-for="(qs,key,i) in paper.questions" :key="i">
              <h5>{{ order[i] }}、{{ getType(key) }}</h5>
              <el-row :gutter="10" v-for="(q,i) in qs" :key="q.id">
                <el-button>{{i+1}}</el-button>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SingleQuestion from '@/components/exam/SingleQuestion'
import MutualQuestion from '@/components/exam/MutualQuestion'
import Completion from '@/components/exam/Completion'

export default {
  name: 'FinishedPaper',
  components: {
    Completion,
    MutualQuestion,
    SingleQuestion
  },
  data () {
    return {
      examId: this.$route.params.examId,
      paper: {},
      order: ['一', '二', '三', '四', '五', '六']
    }
  },
  created () {
    this.getExamPaper()
  },
  methods: {
    submitPaper () {
      this.$confirm('确认是否提交试卷?')
        .then(_ => {
          axios({
            method: 'POST',
            url: 'https://mooc.kirinnorine.top/exam/submit',
            params: {
              id: this.examId
            }
          }).then(res => {
            if (res.data.status) {
              this.$message.success(res.data.message)
              this.$router.go(-1)
            } else {
              this.$message.error(res.data.message)
            }
          })
        })
        .catch(_ => {
        })
    },
    getType (key) {
      switch (key) {
        case 'single':
          return '单选题'
        case 'multiple':
          return '多选题'
        case 'completion':
          return '填空题'
        case 'subjective':
          return '主观题'
      }
    },
    getExamPaper () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/exam/showMyExamPaperAfterSubmissionById',
        params: {
          id: this.examId
        }
      }).then(res => {
        if (res.data.status) {
          this.paper = res.data.data
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
