<template>
  <div>
    <el-row>
      <pre class="description">{{ question.paperQuestion.description }}({{ question.paperQuestion.score }}分)</pre>
    </el-row>
    <el-row>
      <div v-for="(o,i) in question.paperQuestion.paperOptions" :key="o.id">
        <input class="option" type="checkbox" :value="o.id" v-model="question.record" @change="storeRecord"
               :disabled="disabled">
        <label class="optionLabel">
          {{ order[i++] }}、{{ o.description }}
        </label>
      </div>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'MutualQuestion',
  data () {
    return {
      record: [],
      order: ['A', 'B', 'C', 'D', 'E', 'F', 'G']
    }
  },
  props: {
    question: Object,
    disabled: Boolean
  },
  methods: {
    storeRecord () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/paperQuestionRecord/storeRecord',
        data: {
          examId: this.question.examId,
          paperQuestionId: this.question.paperQuestionId,
          record: JSON.stringify(this.record)
        },
        transformRequest: [
          function (data) {
            let ret = ''
            for (const it in data) {
              ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            ret = ret.substring(0, ret.lastIndexOf('&'))
            return ret
          }
        ],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        if (res.status === 200) {
          if (res.data.status) {
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.option {
  font-size: 25px;
  line-height: 1.5;
}

.description {
  margin: 0;
  font-size: 20px;
}
</style>
